<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">固件管理</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit">
						查询
					</a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="固件包">
					<a-input v-model="query.name" placeholder="请输入固件包名称" />
				</a-form-model-item>
				<a-form-model-item prop="type" label="设备型号">
					<a-select placeholder="请选择" v-model="query.type">
						<a-select-option :value="item.class_id" v-for="item in classListNoPage" :key="item.class_id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="版本号">
					<a-input v-model="query.version" placeholder="请输入版本号" />
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-row class="table-action-list" style="margin-top: 20px">
			<a-button type="primary" @click="add" style=" margin-left: 10px">+新增</a-button>
		</a-row>
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="firmware_id" style="margin-top: 14px"
			v-viewer>
			<span slot="type" slot-scope="text, record">
				<a-space>
					{{ getName(classListNoPage, record.type, "class_id", "name") }}
				</a-space>
			</span>
			<span slot="action" slot-scope="text, record">
				<a-space>
					<a-popconfirm title="确认删除" ok-text="是" cancel-text="否" @confirm="del(record.firmware_id)">
						<a style="color: #E0352B">删除</a>
					</a-popconfirm>
				</a-space>
			</span>
		</a-table>
		<template>
			<a-modal title="新增固件" :visible="visible" ok-text="提交" cancel-text="取消" width="28%"
				:confirm-loading="confirmLoading" @ok="submitInfo" @cancel="handleCancel">
				<a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" ref="equipmentForm" :model="form"
					:rules="info_rules">
					<a-form-model-item prop="type" label="设备型号">
						<a-select placeholder="请选择设备型号" v-model="form.type" style="width: 320px;">
							<a-select-option :value="item.class_id" v-for="item in equipmentClass" :key="item.class_id">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item prop="name" label="固件包名称">
						<a-input v-model="form.name" placeholder="请输入" style="width: 320px;"></a-input>
					</a-form-model-item>
					<a-form-model-item prop="version" label="版本号">
						<a-input v-model="form.version" style="width: 320px;" placeholder="请输入"></a-input>
					</a-form-model-item>
					<a-form-model-item prop="firmware_url_name" label="固件包">
						<a-input v-model="form.firmware_url_name" style="width: 200px;" placeholder="驱动程序包仅支持.bin文件"
							disabled>
						</a-input>
						<a-upload style="margin-left: 10px" accept=".bin" :multiple="false" :beforeUpload="beforeUpload"
							:showUploadList="false" :headers="{
								Authorization: 'Bearer ' + token
							}" @change="handleChange" :action="$domain + '/admin/v1/firmware/uploadFirmware'">
							<a-button> <a-icon type="upload" /> 上传固件 </a-button>
						</a-upload>
					</a-form-model-item>
					<a-form-model-item prop="remark" label="备注" placeholder="请输入">
						<a-input v-model="form.remark" type="textarea" style="width: 320px;"></a-input>
					</a-form-model-item>
				</a-form-model>
			</a-modal>
		</template>
	</div>
</template>

<script>
import {
	domain
} from '@/siteInfo.js'

export default {
	data() {
		return {
			query: {
				page: 1,
				limit: 10
			},
			form: {
				firmware_url_name: ""
			},
			pagetotal: 0,
			bind_id: null,
			visible: false,
			equipmentClass: [],
			confirmLoading: false,
			classListNoPage: [],
			columns: [
				{
					title: '固件名称',
					dataIndex: 'name'
				},
				{
					title: '设备型号',
					scopedSlots: {
						customRender: 'type'
					}
				},
				{
					title: '版本号',
					dataIndex: 'version'
				},
				{
					title: '备注',
					dataIndex: 'remark'
				},
				{
					title: '上传时间',
					dataIndex: 'updated_at_str'
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action'
					}
				}
			],
			data: [],
			info_rules: {
				type: [
					{
						required: true,
						message: '请选择设备类型',
						trigger: 'blur'
					}
				],
				name: [
					{
						required: true,
						message: '填写固件包名称',
						trigger: 'blur'
					}
				],
				version: [
					{
						required: true,
						message: '填写版本号',
						trigger: 'blur'
					}
				],
				firmware_url_name: [
					{
						required: true,
						message: '上传固件包',
						trigger: 'blur'
					}
				]
			},
		}
	},
	created() {
		this.getList()
		this.getEquipmentClass()
	},
	computed: {
		token() {
			return this.$store.state.token
		}
	},
	methods: {
		getEquipmentClass() {
			this.$post('equipmentClass/listNoPage', {})
				.then(res => {
					let { code, msg, data } = res
					if (code == 0) {
						this.classListNoPage = data
						this.equipmentClass = data
						console.log("fsadfdsa", this.classListNoPage)
					} else {
						this.$message.error(msg, 1.5)
					}
				})
		},
		beforeUpload(file) {
			this.file = file
			this.uping = true
			this.spinning = true
			console.log('token', this.token)
			console.log('before', file)
		},
		getName(arr, id, idName, lableName) { //通过id获取数组名称
			if (id || id == '0') {
				let searId = id.toString()
				const index = arr.findIndex((role) => role[idName] === searId)
				return index >= 0 ? arr[index][lableName] : undefined
			} else {
				return undefined
			}
		},
		handleChange(e) {
			console.log('eeee', e.file.name)
			console.log('token', this.token)
			console.log('改变。。。')
			if (e.file.status == 'done') {
				console.log(e.file.response)
				this.spinning = false
				this.uping = false
				let { msg, data, code } = e.file.response
				if (code == 0) {
					this.form.firmware_url_name = e.file.name
					this.form.firmware_url = data.really_path
					this.form.sign = data.sign
					this.$message.success(msg)
					console.log("现在的form对象", this.form)
				} else {
					this.$message.warn(msg)
				}
			}
		},
		handleCancel() {
			this.visible = false;
			this.initForm()
		},
		add() {
			this.visible = true;
		},
		initForm() {
			this.form = {
				firmware_url_name: ""
			}
		},
		submitInfo() {
			this.$refs.equipmentForm.validate(valid => {
				if (valid) {
					this.confirmLoading = true

					this.$post("firmware/add", this.form)
						.then(res => {
							let { code, msg, data } = res
							this.confirmLoading = false
							if (code == 0) {
								this.$message.success(msg, 1.5)
								this.handleCancel()
								this.getList()
							} else {
								this.$message.error(msg, 1.5)
							}
						})
						.catch(err => {
							this.confirmLoading = false
						})
				}
			})
		},
		del(id) {
			this.$post(domain + '/admin/v1/firmware/delete', { firmware_id: id }).then(res => {
				if (res.code === 0) {
					this.$message.success(res.msg)
					this.getList()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit,
			}
			this.getList()
		},
		getList() {
			this.$post(domain + '/admin/v1/firmware/list', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList();
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.getList();
			console.log(query)
		}
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4E80F8;
	}

	.title {
		color: #383F50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
